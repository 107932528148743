import React from 'react';
import {withStyles} from '@jpmuitk/theme';
import {ColumnLayout} from '@jpmuitk/column-layout';
import {Route, Routes, useSearchParams} from 'react-router-dom';
import Landing from "./components/Landing";
import {useSelector} from "react-redux";

const styles = {
  root: {
    background: '#F2F4F6',
    height: '100%',
    margin: '0 auto',
    overflow: 'auto',
  },
  panel: {
    flex: 1,
    overflow: 'auto',
  },
  mainContent: {
    flex: 1,
    // maxWidth: '1600px',
    width: '100%',
    height: '100%',
    margin: '0 auto',
    overflow: 'auto',
  },
  spinner: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
};

const App = ({classes}) => {
  const {root, panel, mainContent} = classes;
  const [searchParams, setSearchParams] = useSearchParams();
  const isRunningLocally = window.location.origin.includes('localhost');
  const data = useSelector(state => state.user);

  return (
    <ColumnLayout container className={root} direction="column">
      <Routes>
        <Route path="/" element={<Landing/>}/>
        <Route path="*" element={<Landing/>}/>
      </Routes>
    </ColumnLayout>
  );
};

export default withStyles(styles)(App);
